<template>
  <section class="hot-product m-scroll">
    <!-- 头部导航 -->
    <HeaderBar :title="title">
      <router-link to="/en/cart" slot="right" class="solt-right">
        <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
      </router-link>
    </HeaderBar>

    <goods-columns @cartClick="handleCart" :data="8" :columns="2" style="margin-top:16px"></goods-columns>
    
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MSku         from '@/components/en/m-sku.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'
import HeaderBar from '@/components/en/m-header.vue'

export default {
  name:'Index',
  components:{ GoodsColumns, MSku, MAnimate, CartFlex, HeaderBar },
  data(){
    return {
      recomnd   : 4,
      loading   : false,
      skuModal  : false,
      tabTop    : 44,
      dotAnimate: false,
      start     : false,
      start_dom : null,
      cartNum   : 0,
      scrollTop : false,
      title     : ''
    }
  },
  methods:{
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)

      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    }
  },
  mounted() {
      this.title = this.$route.query.title
  },
  watch: {
      title(val) {
          if(val) {
            this.title = val
          }
      }
  }
}
</script>

<style lang="less" scoped>
.hot-product {
    width: 100%;
    height: 100%;
    padding-top: 44px;
    background-color: #F5F9FF;
    overflow-x: hidden;
    overflow-y: auto;
}
.right-img{width:22px;height:22px;margin:6px}
</style>